



























import { Component, Mixins, Vue, Prop, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import FillRound from '@/components/atoms/FillRound.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import VueRouter from 'vue-router'
import DrillWebApi from '@/mixins/drills/WebApi'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import StudentBottomPanelV3 from '@/components/organisms/v3/StudentBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    FillRound,
    StudentBottomPanel,
    ButtonBase,
    StudentBottomPanelV3,
  },
})
export default class SelectSubject extends Mixins(
  StyleSubjectBackground,
  DrillWebApi,
  LessonStudent,
  ClassModeMethods,
  LoadEnableSubjectsApi,
  ClassModeChangeAble
) {
  private get noticeCurrentPageText() {
    let result = '教科選択中'

    // 補助教材の場合
    if (this.isAssistMode) result = '教材選択中（補助）'

    return result
  }

  private noticePageTitle = ''

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private get componentBottom() {
    return this.isV3 ? 'StudentBottomPanelV3' : 'StudentBottomPanel'
  }

  private classPolicyKKIsEnabled = false

  private async created() {
    if (this.isV3) {
      const modeCode = this.$route.params?.classCategoryCode
      await this.changeClassMode(modeCode, true)
    }
  }

  public async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.resetCurriculumSUnitsGrade()
    await this.checkClassModeAndSideMenuMode(this.isAssistMaterial)
    this.noticeGroup()
    // テストの場合はテスト範囲の設定されている教科のみ実施可能
    if (this.isTestMode) {
      await this.loadTermExamsSubjects()
    } else if (this.isSeminarMode) {
      await this.loadSeminarSubjects()
    } else if (this.isEntranceMode) {
      await this.loadEntranceSubjects()
    } else {
      this.loadEnableSubjects()
    }
    if (this.isNormalMode) await this.classPolicies()
    Vue.prototype.$loading.complete()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    Vue.prototype.$logger.info('-- SelectSubject beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private subjectDatas: { text: string; code: string }[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private serviceCode = Vue.prototype.$cookies.get('dataGdls').serviceCode

  /**
   * クラスカテゴリコード
   * 通常授業の場合は確認テスト、宿題の教科選択で利用
   *
   * 静的ルーティングから遷移してくる場合は何も渡ってこない
   */
  private get classCategoryCode() {
    return this.$route.params.classCategoryCode || ''
  }

  /**
   * 補助教材モード用ルーティングにて遷移した際、trueになる
   * /student/assist-material/subjects/:assistMaterialCode
   */
  @Prop({ default: false })
  isAssistMaterial!: boolean

  @Watch('lessonId')
  private async lessonIdChanged(): Promise<void> {
    // router.pushで宿題用の教科一覧から遷移する場合もあるため確認・宿題に関わらず通常学習であれば確認する
    if (this.isNormalMode) await this.classPolicies()
  }

  // 補助教材の場合、一問一等: OO, 診断テスト: ST, 復習テスト: HS, 補助動画: HD
  private get assistMaterialCode() {
    return this.isAssistMaterial ? this.$route.params.assistMaterialCode : ''
  }

  // 補助教材のテキスト
  private get assistText(): string {
    return [
      this.PRIVATE_SERVICE_DATA.serviceCode,
      this.PRIVATE_FINAL_CHECK_SERVICE_DATA.serviceCode,
      this.PRIVATE_CONFIRM_TEST_SERVICE_DATA.serviceCode,
    ].includes(this.serviceCode)
      ? this.privateServiceTexts[this.privateData?.assistMaterialCode]
      : this.assistMaterialText(this.assistMaterialCode)
  }

  // カテゴリータイトル
  private get categoryTitle() {
    let title = ''
    if (this.isAssistMaterial) {
      title = `(補助教材 - ${this.assistText})`
    } else if (
      (this.isNormalMode && this.classCategoryCode === 'SY') ||
      (this.isTestMode && this.classCategoryCode === 'SY') ||
      (this.isSeminarMode && this.classCategoryCode === 'SY')
    ) {
      title = '(宿題)'
    } else if (this.isTestMode) {
      title = '(定期テスト)'
    } else if (this.isSeminarMode) {
      title = '(講習会)'
    }

    return title
  }

  /**
   * 学習を終了するボタン表示フラグ
   * 定期テストモードと入試対策モードはドリル終了後の戻り先が当画面なので当画面に表示する
   */
  private get showFinishButton(): boolean {
    return (this.isTestMode || this.isEntranceMode) && (this.lessonId || this.justFinishedLessonId)
  }

  /**
   * 学習を終了するリンク
   * 授業中の場合は授業IDを、授業終了している場合は直近に終了した授業終了30分以内の授業IDを返す
   *
   * @return 学習週を終了するリンク
   */
  private get reviewUrl(): string {
    return `/student/review/${this.lessonId || this.justFinishedLessonId}`
  }

  /**
   * 教科選択
   */
  private async selectSubject(subjectCode: string): Promise<any> {
    // 教科コード保存
    await this.setSubjectCode(subjectCode)

    // 補助教材
    if (this.isAssistMaterial) return this.$router.push(`/student/assist-material/select/${this.assistMaterialCode}`)

    if (this.classCategoryCode === 'SY') {
      // 宿題
      this.$router.push(`/student/confirmation/${this.classCategoryCode}`)
    } else {
      if (this.isTestMode) return this.startDrill(subjectCode) // 定期テスト
      if (this.isSeminarMode) return this.$router.push(`/student/seminar-unit-selection`) // 講習会
      if (this.isEntranceMode) return this.$router.push(`/student/entrance-range-selection`) // 入試

      // 通常
      if (this.classCategoryCode === 'KK' && (!this.lessonId || !this.classPolicyKKIsEnabled)) {
        // 確認テストの場合で、授業中でない場合か授業の方針に確認テストの利用許可がなければ確認テスト画面を飛ばす
        this.$router.push('/student/curriculum-s-units')
      } else {
        this.$router.push(`/student/confirmation/${this.classCategoryCode}`)
      }
    }
  }

  /**
   * 教科コードのcookieセット
   */
  private async setSubjectCode(subjectCode: string) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private async startDrill(subjectCode: string): Promise<any> {
    Vue.prototype.$loading.start()
    const startDrill = await this.startDrillApi({
      lessonId: this.lessonId,
      classModeCode: this.currentClassMode,
      classCategoryCode: this.classCategoryCode,
      subjectCode: subjectCode,
    })

    // ドリル開始APIの結果を受けて問題ページへ遷移する
    // 途中で抜けていた場合はそのpageへ遷移
    this.$router.push({
      name: 'drill',
      query: {
        resultDrillId: String(startDrill.resultDrillId),
        pageType: startDrill.pageType,
        page: String(startDrill.page || ''),
        // 以下は引き続き演習問題で利用するのに必要なため引き回す
        classCategoryCode: this.classCategoryCode, // 授業種別
      },
    })
    Vue.prototype.$loading.complete()
  }

  private async loadTermExamsSubjects() {
    await Vue.prototype.$http.httpWithToken.get(`/termExams/subjects?userId=${this.userId}`).then((res: any) => {
      this.subjectDatas = res.data.map((subject: { subjectName: string; subjectCode: string }) => {
        return { text: subject.subjectName, code: subject.subjectCode }
      })
    })
  }

  // 講習会の場合の情報読み込み
  private async loadSeminarSubjects() {
    await Vue.prototype.$http.httpWithToken.get('/seasonSettings/active_subjects').then((res: any) => {
      this.subjectDatas = res.data.map((subject: { subjectName: string; subjectCode: string }) => {
        return { text: subject.subjectName, code: subject.subjectCode }
      })
    })
  }

  // 入試の場合の有効教科取得
  private async loadEntranceSubjects() {
    await Vue.prototype.$http.httpWithToken.get('/entrance/active_subjects').then((res: any) => {
      if (this.isV3 && res.data.length === 0) {
        this.$router.push('/student/v3/dashboard')
      }
      this.subjectDatas = res.data.map((subject: { subjectName: string; subjectCode: string }) => {
        return { text: subject.subjectName, code: subject.subjectCode }
      })
    })
  }

  private async classPolicies() {
    await Vue.prototype.$http.httpWithToken
      .get('/classPolicies/student', { params: { lessonId: this.lessonId } })
      .then((res: any) => {
        const classPolicyKK = res.data.find((policiy: { code: string; isEnabled: boolean }) => {
          return policiy.code === 'KK'
        })
        this.classPolicyKKIsEnabled = classPolicyKK.isEnabled
      })
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    // プルダウン候補の取得
    let enableSubjects = await this.loadGdlsSubjectsApi(this.branchId, this.userId)

    if (this.isAssistMaterial) {
      if (
        [
          this.PRIVATE_SERVICE_DATA.serviceCode,
          this.PRIVATE_FINAL_CHECK_SERVICE_DATA.serviceCode,
          this.PRIVATE_CONFIRM_TEST_SERVICE_DATA.serviceCode,
        ].includes(this.serviceCode)
      ) {
        // 補助教材_全教研専用コースの場合は算数と理科のみ
        enableSubjects = enableSubjects.filter((subject) => this.privateData?.subjectCodes.includes(subject.code))
      } else if (this.assistMaterialCodes.includes(this.assistMaterialCode)) {
        // 補助教材（全教研以外）の選択可能教科
        enableSubjects = enableSubjects.filter((subject) =>
          this.assistMaterialSubjectsCode(this.assistMaterialCode).includes(subject.code)
        )
      }
    }

    this.subjectDatas = enableSubjects.map((subject) => {
      return { text: subject.name, code: subject.code }
    })
  }

  /**
   * 項目リストの学年選択情報をリセットする
   */
  private async resetCurriculumSUnitsGrade() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    delete cookie['curriculumSUnitsGrade']
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }
}
